import { isNotNull } from 'apiServices/common/guards';
import { parseDynamicContent } from 'services/parser/dynamicContentParser';
import { Reference } from 'types/reference';
import { VaccineContraindications, VaccineDetails, VaccineDetailsPublic } from 'types/vaccine';

import {
  isVaccineContraindications,
  ReferenceDTO,
  VaccineContraindications as VaccineContraindicationsDTO,
  VaccineDetailsDTO,
  VaccineDetailsPublicDTO,
} from './DTO';

export const mapReferenceDTO = (reference: ReferenceDTO): Reference => ({
  referenceIdentifier: reference.reference_number,
  citation: reference.citation,
  shortCitation: reference.short_citation,
  href: '',
});

export const mapVaccineContraindicationsDTO = (
  c: VaccineContraindicationsDTO
): VaccineContraindications => ({
  id: c.code,
  name: c.name,
});

export const mapVaccineDetailsDTO = (v: VaccineDetailsDTO): VaccineDetails => ({
  code: v.vaccine_code,
  vaccine: v.vaccine,
  mechanismOfAction: parseDynamicContent(v.mechanism_of_action),
  adultDosage: parseDynamicContent(v.adult_dosage),
  childDosage: parseDynamicContent(v.child_dosage),
  booster: v.booster,
  toxicEffects: v.toxic_effects.filter(isNotNull),
  contraindications: v.contraindications
    .filter(isVaccineContraindications)
    .map(mapVaccineContraindicationsDTO),
  references: v.references.filter(isNotNull).map(mapReferenceDTO),
  referencesLinks: parseDynamicContent(
    v.references
      .filter(isNotNull)
      .map(ref => `{${ref.reference_number}}`)
      .join(' ')
  ),
  synonyms: v.synonyms,
  relevantDiseases: v.diseases
});

export const mapVaccineDetailsPublicDTO = (v: VaccineDetailsPublicDTO): VaccineDetailsPublic => ({
  code: v.vaccine_code,
  vaccine: v.vaccine,
  mechanismOfAction: parseDynamicContent(v.mechanism_of_action),
  adultDosage: parseDynamicContent(v.adult_dosage),
});
