import {
  DrugDetailsPublicDTO,
  FilteredInteractionDTO,
  FilteredToxicityDTO,
  SimpleDrugsDTO,
} from 'apiServices/Drugs/DTO';
import axios from 'axios.cached';
import { getSlug } from 'services/slug/slug.service';
import {
  DrugClass,
  DrugDetails,
  DrugDetailsPublic,
  DrugInteraction,
  DrugSpectrum,
  DrugToxicity,
  FilterType,
} from 'types/drug';
import { SelectOption } from 'types/select';
import { SimpleDrug, SimpleDrugWithSlug } from 'types/simpleDrug';

import { DataDTO } from '../common/DTO';
import { mapDrugDetailsDTO, mapDrugDetailsPublicDTO } from './drugs.mapper';
import {
  DrugClassDTO,
  DrugDetailsDTO,
  DrugInteractionDTO,
  DrugSpectraDTO,
  DrugSpectrumDTO,
  DrugSynonymDTO,
  DrugToxicEffectDTO,
  DrugToxicityDTO,
  FilteredClassAndSpectrumDTO,
} from './DTO';

export const getDrugsSimple = async (isPublic?: boolean): Promise<SimpleDrugWithSlug[]> => {
  const vaccines = await axios.get<SimpleDrugsDTO>(
    isPublic ? '/api/public/drugs' : '/api/drugs?return=simple'
  );

  return vaccines.data.data.map(d => ({
    id: d.drug_code,
    name: d.drug,
    slug: getSlug(d.drug),
  }));
};

export const getDrugDetails = async (code: number): Promise<DrugDetails> => {
  const [drugResponse, effects, interactions, spectrum] = await Promise.all([
    axios.get<DataDTO<DrugDetailsDTO>>(`/api/drugs/${code}/general`),
    axios.get<DataDTO<DrugToxicEffectDTO[]>>(`/api/drugs/${code}/toxic-effects`),
    axios.get<DataDTO<string[]>>(`/api/drugs/${code}/interactions`),
    axios.get<DataDTO<DrugSpectrumDTO[]>>(`/api/drugs/${code}/spectrum`),
  ]);

  return mapDrugDetailsDTO(
    drugResponse.data.data,
    effects.data.data,
    interactions.data.data,
    spectrum.data.data
  );
};

export const getDrugDetailsPublic = async (code: number): Promise<DrugDetailsPublic> => {
  const drugResponse = await axios.get<DataDTO<DrugDetailsPublicDTO>>(`/api/public/drugs/${code}`);

  return mapDrugDetailsPublicDTO(drugResponse.data.data);
};

export const getDrugsToxicity = async (): Promise<DrugToxicity[]> => {
  const toxicities = await axios.get<DataDTO<DrugToxicityDTO[]>>('/api/drugs/toxicity');

  return toxicities.data.data.map(x => ({
    id: x.toxicity_code,
    name: x.toxicity,
  }));
};

export const getDrugsInteraction = async (): Promise<DrugInteraction[]> => {
  const interactions = await axios.get<DataDTO<DrugInteractionDTO[]>>('/api/drugs/interaction');

  return interactions.data.data.map(x => ({
    id: x.code,
    name: x.name,
  }));
};

export const getDrugsClass = async (): Promise<DrugClass[]> => {
  const classes = await axios.get<DataDTO<DrugClassDTO[]>>('/api/drugs/susceptibility/drugclasses');

  return classes.data.data.map(x => ({
    id: x.pathogen_code,
    name: x.pathogen,
  }));
};

export const getDrugsSpectra = async (): Promise<DrugSpectrum[]> => {
  const spectra = await axios.get<DataDTO<DrugSpectraDTO[]>>('/api/drugs/susceptibility/spectra');

  return spectra.data.data.map(x => ({
    id: x.pathogen_code,
    name: x.pathogen.replace('<S>', ''),
    type:x.type
  }));
};

// TODO - api should be fixed to be consistent
export const getDrugsFiltered = async (type: FilterType, code: number): Promise<SimpleDrug[]> => {
  if (type === 'toxicity') {
    const drugs = await axios.get<FilteredToxicityDTO>(`/api/drugs/filter/toxicity/${code}`);
    return drugs.data.data.map(d => ({
      id: d.drug_code,
      name: d.drug,
    }));
  }
  const drugs = await axios.get<FilteredInteractionDTO>(`/api/drugs/filter/interaction/${code}`);
  return drugs.data.data.map(d => ({
    id: d.code,
    name: d.drug,
  }));
};

export const getDrugsBySpectrumAndClass = async (
  classId: string,
  spectrum: SelectOption[]
): Promise<SimpleDrug[]> => {

  const spectraIds = spectrum.filter((x)=>{
    if(x.type !=='virus') return x
  }).map((x)=>x.value).join(',');

  const virusIds = spectrum.filter((x)=>{
    if(x.type ==='virus') return x
  }).map((x)=>x.value).join(',');


  const drugs = await axios.get<FilteredClassAndSpectrumDTO>(
    `/api/drugs/filter/susceptibility?drugclass=${classId}&spectra=${spectraIds}&viruses=${virusIds}`
  );
  return drugs.data.data.map(d => ({
    id: d.drug_code,
    name: d.drug,
  }));

  
};

export const getDrugsSynonyms = async (search?: string, limit = 50): Promise<SimpleDrug[]> => {
  const drugsSynonyms = await axios.get<DrugSynonymDTO>(
    `/api/drugs/synonyms?search=${search}&limit=${limit}`
  );

  return drugsSynonyms.data.data.map(synonym => ({
    id: synonym.code,
    name: synonym.name,
  }));
};

export const getDrugsSynonymsPublic = async (
  search?: string,
  limit = 50
): Promise<SimpleDrug[]> => {
  const drugsSynonyms = await axios.get<DrugSynonymDTO>(
    `/api/public/drugs/synonyms?search=${search}&limit=${limit}`
  );

  return drugsSynonyms.data.data.map(synonym => ({
    id: synonym.code,
    name: synonym.name,
  }));
};
