import { FilledButton } from 'Atoms/buttons/FilledButton';
import { getAriaSort } from 'Atoms/buttons/SortButton';
import { Table, TD, TH, TR } from 'Atoms/table/Table';
import { P } from 'Atoms/text';
import { CellWithSort } from 'Molecules/compare/CellWithSort';
import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { CustomError } from 'types/errorTypes';
import { CaseSeries, Crossborders, Outbreaks, Survey } from 'types/surveyTables';
import { Column, Row } from 'types/table';
import { SortState } from 'types/table';
import { formatNumber } from 'utils/formatNumber';
import {
  getAssociatedInfectionsSurveyData,
  getCaseSeriesTableData,
  getCrossBordersTableData,
  getOutbreaksTableData,
  getSurveyTableData,
} from 'utils/getSurveyTablesData';
import { sortTableRows } from 'utils/sortTableRows';

const StyledP = styled(P)`
  margin-top: 10px;
`;

const StyledTable = styled(Table)`
  margin-top: 10px;
  width: 100%;
`;

const StyledTH = styled(TH)`
  position: relative;
  padding: 8px;

  &.hidden {
    display: none;
  }
`;

const ColumnsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  align-items: center;
`;

const ColumnsDescription = styled.p`
  margin: 3px 5px 3px 0;
  font-size: 14px;
`;

const ColumnItem = styled(FilledButton)`
  width: fit-content;
  padding: 3px 7px;
  border-radius: 10px;
  margin: 3px 7px 3px 0;

  span {
    font-size: 15px !important;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const StyledTD = styled(TD)`
  &.hidden {
    display: none;
  }
`;

type TabelDataProps =
  | { surveyData: Survey[] }
  | { associatedInfectionsSurveyData: Survey[] }
  | { crossBordersData: Crossborders[] }
  | { outbreaksData: Outbreaks[] }
  | { caseSeriesData: CaseSeries[] };

type Props = {
  className?: string;
  footnote1?: string;
  footnote2?: string;
  title?: string;
  subTitle?: string;
  extraColumns?: { name: string; display: string; show: boolean }[];
  addExtraColumn?: (col: string) => void;
} & TabelDataProps;

const getTableColumnsAndRows = (props: TabelDataProps): [Column[], Row[], boolean] => {
  if ('surveyData' in props) {
    return getSurveyTableData(props.surveyData);
  }

  if ('crossBordersData' in props) {
    return getCrossBordersTableData(props.crossBordersData);
  }

  if ('outbreaksData' in props) {
    return getOutbreaksTableData(props.outbreaksData);
  }

  if ('associatedInfectionsSurveyData' in props) {
    return getAssociatedInfectionsSurveyData(props.associatedInfectionsSurveyData);
  }

  if ('caseSeriesData' in props) {
    return getCaseSeriesTableData(props.caseSeriesData);
  }

  throw new CustomError('Missing data for survey table');
};

export const SurveyTable: FC<Props> = ({
  className,
  footnote1,
  footnote2,
  title,
  subTitle,
  extraColumns,
  addExtraColumn,
  ...rest
}) => {
  const [sort, setSort] = useState<SortState>({ asc: false, columnIndex: -1 });
  const [columns, rows, showPublicationFootnote] = getTableColumnsAndRows(rest);

  const onSortClick = (index: number): void => {
    if (index === sort.columnIndex) {
      setSort({ asc: !sort.asc, columnIndex: index });
    } else {
      setSort({ asc: true, columnIndex: index });
    }
  };

  const hideTableHeader = (name: string): boolean => {
    if (!extraColumns) return false;

    const index = extraColumns.findIndex(temp => temp.name === name);
    if (index === -1) return false;
    return !extraColumns[index].show;
  };

  const renderRows = useMemo(() => {
    const sortedRows: Row[] = sortTableRows(rows, sort.asc, sort.columnIndex);

    const hideTableColumn = (index: number): boolean => {
      if (!extraColumns) return false;

      const columnIndex = extraColumns.findIndex(temp => temp.name === columns[index].name);
      if (columnIndex === -1) return false;
      return !extraColumns[columnIndex].show;
    };

    return sortedRows.map((row, i) => (
      <TR key={i}>
        {row.map((rowdata, j) => (
          <StyledTD
            key={j}
            wrap={columns[j].enableWrap}
            minWidth={columns[j].minWidth}
            maxWidth={columns[j].maxWidth}
            textAlign={columns[j].textAlign}
            className={`${hideTableColumn(j) ? 'hidden' : ''}`}
          >
            {rowdata.label ? rowdata.label : ''}
          </StyledTD>
        ))}
      </TR>
    ));
  }, [columns, rows, sort, extraColumns]);

  const renderExtraColumns = useMemo(() => {
    const filteredExtraColumns = (extraColumns || []).filter(
      (col: { name: string; display: string; show: boolean }) => {
        if (col.show) return false;
        const index = columns.findIndex(temp => temp.name === col.name);
        if (index === -1) return false;
        return true;
      }
    );

    return (
      filteredExtraColumns.length > 0 && (
        <ColumnsContainer>
          <ColumnsDescription>Select additional columns:</ColumnsDescription>
          {filteredExtraColumns.map((item, index) => (
            <ColumnItem key={index} onClick={() => addExtraColumn && addExtraColumn(item.name)}>
              +&nbsp;&nbsp;{item.display}
            </ColumnItem>
          ))}
        </ColumnsContainer>
      )
    );
  }, [columns, extraColumns, addExtraColumn]);

  return (
    <>
      {title && (
        <b>
          {title} ({formatNumber(rows.length)})
        </b>
      )}
      {subTitle && <p>{subTitle}</p>}
      {extraColumns && renderExtraColumns}
      <StyledTable
        className={className}
        headings={
          <TR>
            {columns.map((c, i) => (
              <StyledTH
                key={c.name}
                aria-sort={getAriaSort(i !== sort.columnIndex, sort.asc)}
                className={`${hideTableHeader(c.name) ? 'hidden' : ''}`}
              >
                <CellWithSort
                  text={c.name}
                  color="light"
                  showSort={rows.length > 1 && c.isSortable}
                  onClick={() => onSortClick(i)}
                  clicked={i === sort.columnIndex}
                  asc={sort.asc}
                />
              </StyledTH>
            ))}
          </TR>
        }
        rows={renderRows}
      />
      {footnote1 && showPublicationFootnote && <StyledP>{footnote1}</StyledP>}
      {footnote2 && <StyledP>{footnote2}</StyledP>}
    </>
  );
};
