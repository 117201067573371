import {
  DetailedVaccineConcise,
  SimpleVaccinesDTO,
  VaccineContraindicationsDTO,
  VaccineCoverageYear,
  VaccineDetailsDTO,
  VaccineDetailsPublicDTO,
  VaccineSynonymsDTO,
  VaccineToxicityDTO,
} from 'apiServices/Vaccines/DTO';
import axios from 'axios.cached';
import { YearRangeOption } from 'Molecules/YearRangeSelector';
import { getPaginatedData } from 'services/axios.service';
import { getSlug } from 'services/slug/slug.service';
import { SimpleVaccine, SimpleVaccineWithSlug } from 'types/simpleVaccine';
import {
  VaccineContraindications,
  VaccineCoverage,
  VaccineDetails,
  VaccineDetailsConcise,
  VaccineDetailsPublic,
  VaccineSynonym,
  VaccineToxicity,
} from 'types/vaccine';
import { generateArrayRange } from 'utils/generateArray';

import { DataDTO } from '../common/DTO';
import { mapVaccineDetailsDTO, mapVaccineDetailsPublicDTO } from './vaccines.mapper';

export const getVaccinesSimple = async (isPublic?: boolean): Promise<SimpleVaccineWithSlug[]> => {
  const vaccines = await axios.get<SimpleVaccinesDTO>(
    isPublic ? '/api/public/vaccines' : '/api/vaccines?return=simple'
  );

  return vaccines.data.data
    .map(d => ({
      id: d.vaccine_code,
      name: d.vaccine,
      slug: getSlug(d.vaccine),
    }))
    .filter(d => d.name !== '');
};

export const getVaccinesDetailed = async (): Promise<VaccineDetailsConcise[]> => {
  const vaccines = await getPaginatedData<DetailedVaccineConcise>(
    '/api/vaccines?return=detailed',
    100
  );

  return vaccines.data
    .map(d => ({
      id: d.vaccine_code,
      name: d.vaccine || '',
      type: d.vaccine_type,
    }))
    .filter(d => d.name !== '');
};

export const getVaccinesToxicity = async (): Promise<VaccineToxicity[]> => {
  const toxicities = await axios.get<VaccineToxicityDTO>('/api/vaccines/toxicity');

  return toxicities.data.data.map(d => ({
    id: d.id,
    name: d.name,
  }));
};

export const getVaccinesContraindications = async (): Promise<VaccineContraindications[]> => {
  const contraindications = await axios.get<VaccineContraindicationsDTO>(
    '/api/vaccines/contraindications'
  );

  return contraindications.data.data.map(indicator => ({
    id: indicator.code,
    name: indicator.name,
  }));
};

export const getVaccinesFiltered = async (
  type: string,
  value: string
): Promise<SimpleVaccine[]> => {
  const vaccines = await axios.get<SimpleVaccinesDTO>(
    `/api/vaccines/filter/${type}/${value}?return=simple`
  );

  return vaccines.data.data
    .map(d => ({
      id: d.vaccine_code,
      name: d.vaccine || '',
    }))
    .filter(d => d.name !== '');
};

export const getVaccineDetails = async (code: number): Promise<VaccineDetails> => {
  const vaccineResponse = await axios.get<DataDTO<VaccineDetailsDTO>>(
    `/api/vaccines/${code}/general`
  );

  return mapVaccineDetailsDTO(vaccineResponse.data.data);
};

export const getVaccineDetailsPublic = async (code: number): Promise<VaccineDetailsPublic> => {
  const vaccineResponse = await axios.get<DataDTO<VaccineDetailsPublicDTO>>(
    `/api/public/vaccines/${code}`
  );

  return mapVaccineDetailsPublicDTO(vaccineResponse.data.data);
};

export const getVaccineSynonyms = async (search: string, limit = 50): Promise<VaccineSynonym[]> => {
  const vaccineSynonyms = await axios.get<VaccineSynonymsDTO>(
    `/api/vaccines/synonyms?search=${search}&limit=${limit}`
  );

  const data = vaccineSynonyms.data.data || [];

  return data.map(synonym => ({
    autoId: synonym.auto_id,
    name: synonym.vaccine,
    id: synonym.code,
  }));
};

export const getVaccineSynonymsPublic = async (
  search: string,
  limit = 50
): Promise<VaccineSynonym[]> => {
  const vaccineSynonyms = await axios.get<VaccineSynonymsDTO>(
    `/api/public/vaccines/synonyms?search=${search}&limit=${limit}`
  );

  const data = vaccineSynonyms.data.data || [];

  return data.map(synonym => ({
    autoId: synonym.auto_id,
    name: synonym.vaccine,
    id: synonym.code,
  }));
};

export const getVaccinesCoverage = async (abbrev: string, year: string): Promise<VaccineCoverage[]> => {
  const coverages = await axios.get<DataDTO<VaccineCoverage[]>>(`/api/vaccines/coverage/${abbrev}/${year}`);
  const data = coverages.data.data || [];
  return data;
}

export const getVaccineCoverageYearRange = async (abbrev: string): Promise<YearRangeOption[]> => {
  const coverages = await axios.get<DataDTO<VaccineCoverageYear[]>>(`/api/vaccines/coverage/${abbrev}/available-years?return=simple`);
  const data = (coverages.data.data || []).sort((a, b) => a.year - b.year);
  const thisYear = new Date().getFullYear();

  if (data.length === 0) {
    return generateArrayRange(thisYear, thisYear);
  } else if (data.length === 1) {
    return generateArrayRange(data[0].year, data[0].year);
  } else {
    return generateArrayRange(data[0].year, data[data.length - 1].year);
  }
}