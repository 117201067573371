import { isNotNull } from 'apiServices/common/guards';
import { mapImageDTO } from 'apiServices/common/mappers';
import axios from 'axios.cached';
import { getSlug } from 'services/slug/slug.service';
import { DiseaseType } from 'types/az';
import {
  DiseaseCountries,
  DiseaseDetails,
  DiseaseOutbreak,
  Outbreak,
  PublicDiseaseDetails,
} from 'types/disease';
import { DiseaseDistribution } from 'types/diseaseDistribution';
import { Image } from 'types/image';
import { SimpleDisease, SimpleDiseaseWithSlug } from 'types/simpleDisease';

import { DataDTO, ImageDTO } from '../common/DTO';
import {
  mapDiseaseCountries,
  mapDiseaseDistributionDTO,
  mapDiseaseOutbreakDTO,
  mapDiseasesDetailsDTO,
  mapOutbreakSimpleDTO,
  mapPublicDiseasesDetailsDTO,
} from './diseases.mapper';
import {
  DiseaseCountryDTO,
  DiseaseDetailsDTO,
  DiseaseDistributionLegendDTO,
  DiseaseDistributionValueDTO,
  DiseaseOutbreakDTO,
  OutbreakSimpleDTO,
  PublicDiseaseDetailsDTO,
  SimpleDiseasesDTO,
} from './DTO';

export const getDiseasesSimple = async (isPublic?: boolean): Promise<SimpleDiseaseWithSlug[]> => {
  const diseases = await axios.get<SimpleDiseasesDTO>(
    isPublic ? '/api/public/diseases' : '/api/diseases?return=simple'
  );

  return diseases.data.data.map(d => ({
    id: d.disease_code,
    name: d.disease,
    slug: getSlug(d.disease),
  }));
};

export const getDiseasesSimpleFilter = async (category: DiseaseType, isPublic?: boolean): Promise<SimpleDiseaseWithSlug[]> => {
  const DiseaseAgents = {
    'algal': 1000,
    'bacterial': 1002,
    'fungal': 1006,
    'parasitic': 1008,
    'prion': 1107,
    'protoctistal': 1093,
    'viral': 1014,
    'unknown-agent': 1013,
  }

  if (isPublic) {
    return [];
  }

  const diseases = await axios.get<SimpleDiseasesDTO>(`/api/diseases/filter?return=simple&agent=${DiseaseAgents[category]}`);

  return diseases.data.data.map(d => ({
    id: d.disease_code,
    name: d.disease,
    slug: getSlug(d.disease),
  }));
};

export const getDiseaseDetails = async (diseaseId: number): Promise<DiseaseDetails> => {
  const diseaseDetails = await axios.get<DataDTO<DiseaseDetailsDTO>>(
    `/api/diseases/${diseaseId}/general`
  );

  return mapDiseasesDetailsDTO(diseaseDetails.data.data);
};

export const getDiseaseImages = async (diseaseId: number): Promise<Image[]> => {
  const images = await axios.get<DataDTO<ImageDTO[]>>(`/api/diseases/${diseaseId}/images`);

  return images.data.data.map(mapImageDTO);
};

export const getDiseaseCountries = async (diseaseId: number): Promise<DiseaseCountries> => {
  const countries = await axios.get<DataDTO<DiseaseCountryDTO[]>>(
    `/api/diseases/${diseaseId}/countries`
  );

  return mapDiseaseCountries(countries.data.data);
};

export const getDiseaseSynonyms = async (search?: string, limit = 50): Promise<SimpleDisease[]> => {
  const diseasesSynonyms = await axios.get<SimpleDiseasesDTO>(
    `/api/diseases/synonyms?search=${search}&limit=${limit}`
  );

  return diseasesSynonyms.data.data.map(synonym => ({
    id: synonym.disease_code,
    name: synonym.disease,
  }));
};

export const getDiseaseOutbreaks = async (diseaseId: number): Promise<DiseaseOutbreak[]> => {
  const diseasesOutbreaks = await axios.get<DataDTO<DiseaseOutbreakDTO[]>>(
    `/api/diseases/${diseaseId}/outbreaks`
  );

  return diseasesOutbreaks.data.data.map(mapDiseaseOutbreakDTO).filter(isNotNull);
};

export const getDiseaseDistribution = async (diseaseId: number): Promise<DiseaseDistribution> => {
  const [diseasesDistributionLegend, diseasesDistributionValues] = await Promise.all([
    axios.get<DataDTO<DiseaseDistributionLegendDTO[]>>(
      `/api/diseases/${diseaseId}/distribution/map/legends`
    ),
    axios.get<DataDTO<DiseaseDistributionValueDTO[]>>(
      `/api/diseases/${diseaseId}/distribution/map/values`
    ),
  ]);

  return mapDiseaseDistributionDTO(
    diseasesDistributionLegend.data.data,
    diseasesDistributionValues.data.data
  );
};

export const getOutbreaks = async (year: string): Promise<Outbreak[]> => {
  const params = new URLSearchParams({ year: year, return: 'simple' });

  const diseasesOutbreaks = await axios.get<DataDTO<OutbreakSimpleDTO[]>>(
    `/api/diseases/outbreaks`,
    {
      params: params,
    }
  );

  return diseasesOutbreaks.data.data.map(mapOutbreakSimpleDTO).filter(isNotNull);
};

export const getOutbreaksPublic = async (year: string): Promise<Outbreak[]> => {
  const params = new URLSearchParams({ year: year, return: 'simple' });

  const diseasesOutbreaks = await axios.get<DataDTO<OutbreakSimpleDTO[]>>(
    `/api/public/diseases/outbreaks`,
    {
      params: params,
    }
  );

  return diseasesOutbreaks.data.data.map(mapOutbreakSimpleDTO).filter(isNotNull);
};

export const getDiseaseDetailsPublic = async (diseaseId: number): Promise<PublicDiseaseDetails> => {
  const diseaseDetails = await axios.get<DataDTO<PublicDiseaseDetailsDTO>>(
    `/api/public/diseases/${diseaseId}`
  );

  return mapPublicDiseasesDetailsDTO(diseaseDetails.data.data);
};

export const getDiseaseSynonymsPublic = async (
  search?: string,
  limit = 50
): Promise<SimpleDisease[]> => {
  const diseasesSynonyms = await axios.get<SimpleDiseasesDTO>(
    `/api/public/diseases/synonyms?search=${search}&limit=${limit}`
  );

  return diseasesSynonyms.data.data.map(synonym => ({
    id: synonym.disease_code,
    name: synonym.disease,
  }));
};

export const getDiseaseCountriesPublic = async (diseaseId: number): Promise<DiseaseCountries> => {
  const countries = await axios.get<DataDTO<DiseaseCountryDTO[]>>(
    `/api/public/diseases/${diseaseId}/countries`
  );

  return mapDiseaseCountries(countries.data.data);
};
