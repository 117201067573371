import { getCountryNotes, getCountryNotesPublic } from 'apiServices/countryNotes/countryNotes';
import React, { useEffect } from 'react';
import { assertIsNotStoreError, isStoreError, newStoreError } from 'store/storeError';
import { Dispatch, isLoading, Loading, Resource } from 'store/types';
import { CountryNotes, CountryNotesPublic } from 'types/countryNotes';
import { CaseSeries, Crossborders, Outbreaks, Survey } from 'types/surveyTables';

import { Action, CountryNotesDispatchContext, CountryNotesStateContext, State } from './provider';

export const useState = (): State => {
  const state = React.useContext(CountryNotesStateContext);
  if (state === undefined) {
    throw new Error('Country notes store is not initialized');
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(CountryNotesDispatchContext);
  if (dispatch === undefined) {
    throw new Error('Country notes store is not initialized');
  }
  return dispatch;
};

export const useCountryNotes = (diseaseId: number, countryId: string): Resource<CountryNotes> => {
  const state = useState();
  const dispatch = useDispatch();
  const indentifier = diseaseId.toString() + countryId;
  const countryNotes = state.countryNotes[indentifier];

  useEffect(() => {
    if (!countryNotes) {
      dispatch({ type: 'CountryNotes/SingleLoadInitiated', payload: { indentifier } });
      getCountryNotes(diseaseId, countryId)
        .then(data =>
          dispatch({ type: 'CountryNotes/SingleLoaded', payload: { indentifier, data } })
        )
        .catch(err => {
          dispatch({
            type: 'CountryNotes/SingleLoadFailed',
            payload: { indentifier, error: newStoreError(err.message, err.code, err) },
          });
        });
    }
  }, [dispatch, countryNotes, countryId, diseaseId, indentifier]);

  return countryNotes || Loading;
};

export const useCountryNotesPublic = (
  diseaseId: number,
  countryId: string
): CountryNotesPublic | 'Loading' => {
  const state = useState();
  const dispatch = useDispatch();
  const indentifier = diseaseId.toString() + countryId;
  const countryNotes = state.countryNotesPublic[indentifier];

  useEffect(() => {
    if (!countryNotes) {
      dispatch({ type: 'CountryNotes/Public/SingleLoadInitiated', payload: { indentifier } });
      getCountryNotesPublic(diseaseId, countryId)
        .then(data =>
          dispatch({ type: 'CountryNotes/Public/SingleLoaded', payload: { indentifier, data } })
        )
        .catch(err => {
          dispatch({
            type: 'CountryNotes/Public/SingleLoadFailed',
            payload: { indentifier, error: newStoreError(err.message, err.code, err) },
          });
        });
    }
  }, [dispatch, countryNotes, countryId, diseaseId, indentifier]);

  assertIsNotStoreError(countryNotes);

  return countryNotes || Loading;
};

const useCountryNotesSlice = <K extends keyof CountryNotes>(
  key: K,
  diseaseId: number,
  countryId: string
): Resource<CountryNotes[K]> => {
  const state = useState();
  const indentifier = diseaseId.toString() + countryId;
  const countryNotes = state.countryNotes[indentifier];

  if (!countryNotes) {
    return Loading;
  }

  if (isLoading(countryNotes) || isStoreError(countryNotes)) {
    return countryNotes;
  }

  return countryNotes[key];
};

export const useCountryPrevalenceSurveys = (
  diseaseId: number,
  countryId: string
): Resource<Survey[]> => useCountryNotesSlice('prevalence', diseaseId, countryId);

export const useCountrySeroprevalenceSurveys = (
  diseaseId: number,
  countryId: string
): Resource<Survey[]> => useCountryNotesSlice('seroprevalence', diseaseId, countryId);

export const useCountryAssociatedInfectionsSurveys = (
  diseaseId: number,
  countryId: string
): Resource<Survey[]> => useCountryNotesSlice('associatedInfections', diseaseId, countryId);

export const useCountryCrossborders = (
  diseaseId: number,
  countryId: string
): Resource<Crossborders[]> => useCountryNotesSlice('crossborders', diseaseId, countryId);

export const useCountryOutbreaks = (
  diseaseId: number,
  countryId: string
): Resource<Outbreaks[]> =>   useCountryNotesSlice('outbreaks', diseaseId, countryId);

export const useCountryCaseSeries = (
  diseaseId: number,
  countryId: string
): Resource<CaseSeries[]> =>   useCountryNotesSlice('caseSeries', diseaseId, countryId);
