import { ReactComponent as GraphSVG } from 'assets/Button/Graph.svg';
import { ReactComponent as DiseaseSvg } from 'assets/ExploreFocus/Diseases.svg';
import { ReactComponent as MapSvg } from 'assets/UI/Map.svg';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { Loader } from 'Atoms/Loader';
import { MainLayout } from 'layouts/MainLayout';
import { RecordLayout } from 'layouts/RecordLayout';
import { RecordLink, RecordLinks } from 'Molecules/record/RecordLinks';
import { RecordSection } from 'Molecules/record/RecordSection';
import { RecordTitle, TitleContainer } from 'Molecules/record/RecordTitle';
import { DownloadsSection } from 'Molecules/section/DownloadsSection';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import { getStringFromDynamicHtml } from 'Organisms/dynamicContent/DynamicHtmlToString';
import { ReferenceLabelContext } from 'Organisms/dynamicContent/ReferenceMarker';
import { NotFound } from 'pages/404/';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { getTimeStamp } from 'services/getLatestOutbreakLabel';
import { ReferenceProvider } from 'services/referenceProvider/ReferenceProvider';
import { usePushState } from 'services/usePushState.hook';
import { useCountryNotes } from 'store/countryNotesStore/hooks';
import { useDiseaseDetails } from 'store/diseaseDetailStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';

const largeNotes = [11170];

const StyledRecordTitle = styled(RecordTitle)`
  ${TitleContainer} {
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-top: 15px;
  }
`;

const StyledButton = styled(FilledButton)`
  min-width: 48px;
  min-height: 48px;
  max-width: 48px;
  max-height: 48px;
  & > span {
    display: flex;
    flex-direction: column;
  }
  padding: 0;
`;

const StatsButton = styled(StyledButton)`
  margin-left: 10px;
`;

const DiseaseButton = styled(StyledButton)`
  margin-left: 10px;
`;

const StyledIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  margin-bottom: 4px;
`;

type Props = RouteComponentProps & {
  diseaseId: number;
};

export const WorldwideNotes: FC<Props> = ({ match, diseaseId }) => {
  const [showDistribution, setShowDistribution] = useState(true);
  const [showDownloads, setShowDownloads] = useState(true);
  const { push } = usePushState();

  const disease = useDiseaseDetails(diseaseId);

  const worldwideNotes = useCountryNotes(diseaseId, 'G100');

  if (isLoading(disease) || isLoading(worldwideNotes)) {
    return (
      <Loader
        message={
          largeNotes.includes(diseaseId)
            ? 'This is a large note, it may take a moment to load.'
            : undefined
        }
      />
    );
  }

  assertIsNotStoreError(disease);
  assertIsNotStoreError(worldwideNotes);

  if (!disease.countries.isWorldwideAvailable) {
    return <NotFound />;
  }

  const path = match.url.split('/');

  const description = `GIDEON - ${
    disease.details.disease
  } - Worldwide note. Worldwide distribution: ${getStringFromDynamicHtml(worldwideNotes.note)}`;

  const title = `${disease.details.disease} worldwide distribution`;

  const sections: RecordLink[] = [
    {
      label: 'Worldwide distribution',
      'aria-label': title,
    },
    {
      label: 'Downloads and references',
      'aria-label': `${disease.details.disease} worldwide downloads and references`,
    },
  ];

  return (
    <ReferenceProvider>
      <MainLayout>
        <Helmet>
          <title>{title} - GIDEON</title>
          <meta name="description" content={description} />
        </Helmet>
        <ReferenceLabelContext.Provider value={title}>
          <RecordLayout>
            <StyledRecordTitle
              title={title}
              buttons={
                <Buttons>
                  <StyledButton
                    onClick={() => {
                      push(
                        path
                          .filter((_, i) => i < path.length - 1)
                          .concat(['outbreaks'])
                          .join('/')
                      );
                    }}
                    size="micro"
                  >
                    <StyledIcon svgComponent={MapSvg} />
                    Map
                  </StyledButton>
                  <DiseaseButton
                    onClick={() => {
                      push(path.filter((_, i) => i < path.length - 1).join('/'));
                    }}
                    size="micro"
                  >
                    <StyledIcon svgComponent={DiseaseSvg} />
                    Disease
                  </DiseaseButton>
                  <StatsButton
                    padding="none"
                    size="micro"
                    onClick={() => push(`/visualize/graphs?disease=${diseaseId}&country=G100`)}
                  >
                    <StyledIcon svgComponent={GraphSVG} />
                    Stats
                  </StatsButton>
                </Buttons>
              }
              showTimeStamp={getTimeStamp(worldwideNotes.timestamp)}
            />
            <RecordLinks links={sections} />
            <RecordSection
              link={sections[0]}
              show={showDistribution}
              onCollapse={() => setShowDistribution(!showDistribution)}
              accent="first"
              id="worldwide-note"
            >
              <DynamicHtml content={worldwideNotes.note} />
            </RecordSection>
            <RecordSection
              link={sections[1]}
              show={showDownloads}
              onCollapse={() => setShowDownloads(!showDownloads)}
              accent="second"
              id="worldwide-download"
            >
              <DownloadsSection id={`${diseaseId}/G100`} category="diseases" labelTitle={title} />
            </RecordSection>
          </RecordLayout>
        </ReferenceLabelContext.Provider>
      </MainLayout>
    </ReferenceProvider>
  );
};
