import { ReactComponent as GraphSVG } from 'assets/Button/Graph.svg';
import { ReactComponent as DiseasesIcon } from 'assets/ExploreFocus/Diseases.svg';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { Link } from 'Atoms/links/Link';
import { Loader } from 'Atoms/Loader';
import { H2, P } from 'Atoms/text';
import { MainLayout } from 'layouts/MainLayout';
import { RecordLayout } from 'layouts/RecordLayout';
import { RecordLink, RecordLinks } from 'Molecules/record/RecordLinks';
import { RecordSection } from 'Molecules/record/RecordSection';
import { RecordTitle } from 'Molecules/record/RecordTitle';
import { CountryNoteSection } from 'Molecules/section/CountryNoteSection';
import { DownloadsSection } from 'Molecules/section/DownloadsSection';
import { ReferenceLabelContext } from 'Organisms/dynamicContent/ReferenceMarker';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { getLatestOutbreakLabel, getTimeStamp } from 'services/getLatestOutbreakLabel';
import { ReferenceProvider } from 'services/referenceProvider/ReferenceProvider';
import { usePushState } from 'services/usePushState.hook';
import { useCountriesResource } from 'store/countriesStore/hooks';
import { useCountryLatestOutbreaks } from 'store/countryLatestOutbreaksStore/hooks';
import { useCountryNotes } from 'store/countryNotesStore/hooks';
import { useDiseasesResource } from 'store/diseasesStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';
import { CustomError, ErrorCodes } from 'types/errorTypes';

const StyledButton = styled(FilledButton)`
  min-width: 48px;
  min-height: 48px;
  margin-left: 10px;
  max-width: 48px;
  max-height: 48px;
  & > span {
    display: flex;
    flex-direction: column;
  }
`;

const StyledIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  margin-bottom: 4px;
`;

const NoDataTitle = styled(H2)`
  text-align: left;
  padding: 10px 43px;
  margin: 0;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 10px 28px;
  }
`;

type Props = RouteComponentProps & {
  diseaseId: number;
  countryId: string;
};

export const DiseaseInCountry: FC<Props> = ({ match, countryId, diseaseId }) => {
  const { push } = usePushState();
  const [showDistribution, setShowDistribution] = useState(true);
  const [showDownloads, setShowDownloads] = useState(true);

  const diseases = useDiseasesResource();
  const countries = useCountriesResource();
  const countryNotes = useCountryNotes(diseaseId, countryId);
  const latestOutbreaks = useCountryLatestOutbreaks(countryId);

  if (
    isLoading(diseases) ||
    isLoading(countries) ||
    isLoading(countryNotes) ||
    isLoading(latestOutbreaks)
  ) {
    return <Loader />;
  }

  assertIsNotStoreError(diseases);
  assertIsNotStoreError(countries);
  assertIsNotStoreError(countryNotes);

  const disease = diseases.data[diseaseId].name;
  const country = countries.data.find(x => x.id === countryId);

  if (!country) {
    throw new CustomError(
      `Country ${countryId} for disease ${diseaseId} not found`,
      ErrorCodes.NotFound
    );
  }

  const noteForUK =
    countryId === 'G291' ? (
      <>
        <P>
          In many cases, data published for the United Kingdom include reports from England and
          Wales only.
        </P>
        <P>
          As such, <Link to={`/explore/diseases/${diseaseId}/G357`}>Northern Ireland</Link> and{' '}
          <Link to={`/explore/diseases/${diseaseId}/G327`}>Scotland</Link> are listed as additional
          countries.
        </P>
      </>
    ) : undefined;

  const sections: RecordLink[] = [
    { label: 'Country note', 'aria-label': `${disease} in ${country.name} note` },
    {
      label: 'Downloads and references',
      'aria-label': `${disease} in ${country.name} downloads and references`,
    },
  ];

  const title = `${disease} in ${country.name}`;

  return (
    <ReferenceProvider>
      <MainLayout>
        <Helmet>
          <title>{title} - GIDEON</title>
        </Helmet>
        <ReferenceLabelContext.Provider value={title}>
          <RecordLayout>
            <RecordTitle
              title={title}
              buttons={
                <>
                  <StyledButton
                    onClick={() => {
                      const path = match.url.split('/');
                      push(path.filter((_, i) => i < path.length - 1).join('/'));
                    }}
                    size="micro"
                  >
                    <StyledIcon svgComponent={DiseasesIcon} />
                    Disease
                  </StyledButton>

                  <StyledButton
                    padding="none"
                    size="micro"
                    onClick={() =>
                      push(`/visualize/graphs?disease=${diseaseId}&country=${countryId}`)
                    }
                  >
                    <StyledIcon svgComponent={GraphSVG} />
                    Stats
                  </StyledButton>
                </>
              }
              note={noteForUK}
              subtitle={getLatestOutbreakLabel(diseaseId, latestOutbreaks)}
              showTimeStamp={getTimeStamp(countryNotes.timestamp)}
            />
            {countryNotes.note && countryNotes.note.length > 0 ? (
              <>
                <RecordLinks links={sections} />
                <RecordSection
                  link={sections[0]}
                  show={showDistribution}
                  onCollapse={() => setShowDistribution(!showDistribution)}
                  accent="first"
                  id="disease-country-note"
                >
                  <CountryNoteSection
                    disease={disease}
                    diseaseId={diseaseId}
                    note={countryNotes.note}
                    countries={countryNotes.relatedCountries}
                    diseases={countryNotes.relatedDiseases}
                  />
                </RecordSection>
                <RecordSection
                  link={sections[1]}
                  show={showDownloads}
                  onCollapse={() => setShowDownloads(!showDownloads)}
                  accent="second"
                  id="disease-country-download"
                >
                  <DownloadsSection
                    id={`${diseaseId}/${countryId}`}
                    category="diseases"
                    labelTitle={title}
                  />
                </RecordSection>
              </>
            ) : (
              <NoDataTitle size="regular">
                GIDEON database has no data on {disease} in {country.name}
              </NoDataTitle>
            )}
          </RecordLayout>
        </ReferenceLabelContext.Provider>
      </MainLayout>
    </ReferenceProvider>
  );
};
