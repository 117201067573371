import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { Link } from 'Atoms/links/Link';
import { Loader } from 'Atoms/Loader';
import { P, Span } from 'Atoms/text';
import { LightTooltip } from 'Atoms/tooltip/LightTooltip';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import { Popper } from 'Organisms/Popper';
import React, { FC, MouseEvent, useEffect, useState } from 'react';
import { useAbbreviationDetails } from 'store/abbreviationsStore/hooks';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';
import { RegionVaccineSchedule, VaccineSchedule } from 'types/countryDetails';
import { DynamicContent } from 'types/dynamicContent';
import { VaccineAbbreviation } from 'types/vaccineAbbreviation';

const Wrapper = styled.div`
  position: relative;
`;

const Button = styled(InvisibleButton)`
  color: ${props => props.theme.colors.text.lightLink};
  cursor: pointer;
  font: ${props => props.theme.fonts.normalText};
`;

const StyledP = styled(P)`
  margin-bottom: 10px;
`;

const StyledSpan = styled(Span)`
  margin-right: 5px;
`;

const RelatedVaccines = styled.div`
  margin-top: 15px;
`;

const StyledLink = styled(Link)`
  display: block;
  margin-bottom: 8px;
`;

const StyledLightTooltip = styled(LightTooltip)`
  min-width: 150px;
  min-height: 150px;
`;

interface ActiveVacinePopover {
  element: HTMLButtonElement;
  id: string;
}

interface SingleVaccineProps {
  schedule: VaccineSchedule;
  setReferenceElement: (ref: ActiveVacinePopover | null) => void;
}

const SingleVaccine: FC<SingleVaccineProps> = ({ schedule, setReferenceElement }) => {
  const getReferences = (schedule: VaccineSchedule): DynamicContent => {
    const content: DynamicContent = [];
    content.push({ type: 'text', value: schedule.schedule });
    if (schedule.references && schedule.references.length > 0) {
      for (const reference of schedule.references.split(',')) {
        content.push({
          type: 'reference',
          code: reference.trim().split(' ')[0],
          value: reference.trim().split(' ')[1],
        });
      }
    }
    return content;
  };

  return (
    <div>
      <Button
        type="button"
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          setReferenceElement({
            element: event.currentTarget,
            id: schedule.vaccine,
          });
        }}
        aria-label={`${schedule.vaccine} contains the composite vaccine name explanation and links to GIDEON vaccine records`}
      >
        {schedule.vaccine}
      </Button>
      {' - '}
      <DynamicHtml content={getReferences(schedule)} />
    </div>
  );
};

interface VaccineTooltipProps {
  referenceElement: ActiveVacinePopover | null;
  setReferenceElement: (ref: ActiveVacinePopover | null) => void;
  vaccineDetails: VaccineAbbreviation | 'Loading' | undefined;
}

const VaccineTooltip: FC<VaccineTooltipProps> = ({
  referenceElement,
  setReferenceElement,
  vaccineDetails,
}) => (
  <Popper referenceElement={referenceElement?.element} isOpen={!!referenceElement?.element}>
    {referenceElement && vaccineDetails ? (
      isLoading(vaccineDetails) ? (
        <StyledLightTooltip onClose={() => setReferenceElement(null)}>
          <Loader />
        </StyledLightTooltip>
      ) : (
        <LightTooltip onClose={() => setReferenceElement(null)}>
          {vaccineDetails.isComposite && (
            <StyledP>
              <StyledSpan color="darkLink" weight="700">
                {vaccineDetails.name}
              </StyledSpan>
              <Span>is a composite vaccine.</Span>
            </StyledP>
          )}
          {vaccineDetails.explanation.split(', ').map(x => (
            <StyledP key={x}>{x}</StyledP>
          ))}
          {vaccineDetails.relatedVaccines.length > 0 && (
            <RelatedVaccines>
              <StyledP weight="500">Related vaccines and globulins:</StyledP>
              {vaccineDetails.relatedVaccines.map(x => (
                <StyledLink key={x.id} to={`/explore/vaccines/${x.id}`}>
                  {x.name}
                </StyledLink>
              ))}
            </RelatedVaccines>
          )}
        </LightTooltip>
      )
    ) : null}
  </Popper>
);

interface Props {
  data: RegionVaccineSchedule[];
}

export const VaccinationSection: FC<Props> = ({ data }) => {
  const [referenceElement, setReferenceElement] = useState<ActiveVacinePopover | null>(null);
  const { getAbbreviation } = useAbbreviationDetails();
  const [vaccineDetails, setVaccineDetails] = useState<VaccineAbbreviation | 'Loading'>();

  const refId = referenceElement?.id;
  useEffect(() => {
    refId && setVaccineDetails(getAbbreviation(refId));
  }, [refId, getAbbreviation]);

  return data.length > 0 ? (
    <Wrapper>
      {data.map((x, i) => (
        <div key={i}>
          {x.region && (
            <>
              <b>{x.region}</b>
              <br />
            </>
          )}
          <br />
          {x.vaccines.map(schedule => (
            <SingleVaccine
              key={schedule.vaccine}
              schedule={schedule}
              setReferenceElement={setReferenceElement}
            />
          ))}
          <br />
        </div>
      ))}
      <VaccineTooltip
        vaccineDetails={vaccineDetails}
        setReferenceElement={setReferenceElement}
        referenceElement={referenceElement}
      />
    </Wrapper>
  ) : (
    <P>Vaccine schedule is not known for this country</P>
  );
};
